// export const fetcher = async (...args: [key: string, ...any]) => {
//   console.log('*** args:', ...args)
//   // console.log('fff:', fff)
//   const res = await fetch(...args).then((res) => res.json());
//   // console.log('*** res:', res)
//   return res;
// };

export const fetcher = async (params: any) => {
  const [url, headers] = params;

  if (!headers.headers.Authorization) {
    return;
  }

  const res = await fetch(url, headers).then((res) => res.json());

  return res;
};
